<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('notice.title')" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('notice.subtitle')" prop="subtitle">
          <el-input v-model="ruleForm.subtitle"></el-input>
        </el-form-item>
        <el-form-item :label="$t('notice.order')" prop="order">
          <el-input
            @input="
              (res) => {
                this.ruleForm.order = +res;
              }
            "
            @mousewheel.native.prevent  
            @DOMMouseScroll.native.prevent 
            type="number"
            placeholder="정렬순이 높은 건이 우선 노출됩니다."
            v-model="ruleForm.order"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('notice.is_home')" prop="is_home">
          <el-radio-group v-model="ruleForm.is_home">
            <el-radio :label="0">{{ $t("notice.is_home0") }}</el-radio>
            <el-radio :label="1">{{ $t("notice.is_home1") }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.is_home === 1"
          :label="$t('notice.cover')"
          prop="cover"
        >
          <upload-img
            :limitMax="1"
            :file-list="ruleForm.cover"
            @changeImgList="changeImgList"
          ></upload-img>
        </el-form-item>
        <el-form-item :label="$t('notice.content')" prop="content">
          <div v-if="load">
            <SummerNote
              :id="'summernote' + new Date().getTime()"
              v-model="ruleForm.content"
            />
          </div>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import { clean } from "@/utils/object";
import uploadImg from "@/components/uploadImg";
import SummerNote from "@/components/summernote";
import { noticeDetail, noticeCreate, noticeEdit } from "@/api/index";
export default {
  name: "editNotice",
  data() {
    return {
      ruleForm: {
        title: "",
        subtitle: "",
        order: 0,
        content: "",
        is_home: 0,
        cover: "",
      },
      rules: {
        title: [
          { required: true, message: this.$t("notice").title, trigger: "blur" },
        ],
        subtitle: [
          {
            required: true,
            message: this.$t("notice").subtitle,
            trigger: "blur",
          },
        ],
        order: [
          {
            required: true,
            message: "정렬순이 높은 건이 우선 노출됩니다.",
            trigger: "blur",
          },
        ],
        is_home: [
          {
            required: true,
            message: this.$t("notice").is_home,
            trigger: "blur",
          },
        ],
        cover: [
          {
            required: false,
            message: this.$t("notice").cover,
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: this.$t("notice").content,
            trigger: "blur",
          },
        ],
      },
      load: false,
    };
  },
  components: { SummerNote, uploadImg },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  watch: {
    "ruleForm.is_home": {
      handler() {
        this.ruleForm.is_home === 0
          ? (this.rules.cover[0].required = false)
          : (this.rules.cover[0].required = true);
      },
      immediate: true,
    },
  },
  methods: {
    changeImgList(arr) {
      if (arr.length === 0) {
        this.ruleForm.cover = "";
      } else {
        this.ruleForm.cover = arr[0];
      }
    },
    async submitForm(formName) {
      await this.$refs[formName].validate();
      let data = clean({ ...this.ruleForm });
      if (this.id === undefined) {
        await noticeCreate(data);
      } else {
        delete data.create_time;
        delete data.update_time;
        await noticeEdit(data);
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await noticeDetail(this.id);
      information.cover === null ? (information.cover = "") : "";
      this.ruleForm = { ...information };
      this.load = true;
    },
  },
  async created() {
    if (this.id === undefined) {
      this.load = true;
      return;
    }
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
